import React from "react"
import Header from "../general/header"
import MiniHeader from "../general/mini-header"
import Footer from "../general/footer"
import JSONData from "../../data/texts.json"

export default function WideContentLayout({ children, page, classes }) {
  return (
    <>
      <Header menu={JSONData.menu}/>
      <MiniHeader page={page}/>
      <section className="section py-5 content-section" id="content">
        <div className={`container ${classes}`}>
          {children}
        </div>
      </section>
      <Footer/>
    </>
  )
}