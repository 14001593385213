import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import { SEO } from '../components'
import WideContentLayout from "../components/layouts/wide-content"
import HTMLContent from "../components/general/html-content";
import "../assets/styles/components/features-page.scss";


export const FeaturesPageTemplate = props => {
  const { page } = props;
  const data = page.frontmatter;

  const pageData = {
    title: data.title,
    description: data.subtitle
  }

  return (
    <main >
      <title>{data.title}</title>
      <WideContentLayout page={pageData} classes="features-page">
        {/* The page.html is actually markdown when viewing the page from the netlify CMS,
              so we must use the ReactMarkdown component to parse the markdown in that case  */}
          {page.bodyIsMarkdown ? (
            <ReactMarkdown source={page.html} />
          ) : (
            <HTMLContent content={page.html} />
          )}
          {data.items.map( (item, index) => {
            if (index % 2 !== 0) {
              return <LeftSide item={item} key={`feature-left-${index}`}/>
            }
            return <RightSide item={item} key={`feature-right-${index}`}/>
            
          })}
      </WideContentLayout>
    </main>
  );
};

const LeftSide = props => {
  const { item } = props
  return (
    <div className="col row p-0 p-md-5 m-0 mb-4">
      <div className="col-12 col-md-6 px-0 pr-md-5">
        <h3 className="mb-3">{item.title}</h3>
        <p>
          {item.text}
        </p>
        {item.link ? <a href={item.link.url}>{item.link.text}</a>: ""}
      </div>
      <div className="col-12 col-md-6 mb-5 p-0">
        <div className="p-3 feature-img-container">
          <img src={item.image} className="right"/>
        </div>
      </div>
    </div>
  )
}

const RightSide = props => {
  const { item } = props;
  return (
    <div className="col row p-0 p-md-5 mx-0 mb-4">
      <div className="col-12 col-md-6 mb-5 p-0">
        <div className="p-3 feature-img-container">
          <img src={item.image} />
        </div>
      </div>
      <div className="col-12 col-md-6 pl-0 pl-sm-5">
        <h3 className="mb-3">{item.title}</h3>
        <p>
          {item.text}
        </p>
        {item.link ? <a href={item.link.url}>{item.link.text}</a>: ""}
      </div>
    </div>
  )
}

const FeaturesPage = ({ data }) => {
  const isBrowser = typeof window !== "undefined"
  const pathName = isBrowser ? window.location.pathname: ""

  const { markdownRemark: page } = data;
  const {
    frontmatter: {
      seo: { title: seoTitle, description: seoDescription },
    },
  } = page;

  return (
    <>
      <SEO
        title={seoTitle}
        desc={seoDescription}
        pathname={pathName}
      />
      <FeaturesPageTemplate page={{ ...page, bodyIsMarkdown: false }} />
    </>
  );
};

FeaturesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FeaturesPage;

export const featuresPageQuery = graphql`
  query FeaturesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        seo {
          title
          description
        }
        items {
          title
          image
          text
          link {
            text
            url
          }
        }
      }
    }
  }
`;